input.form-control-lg::placeholder {
	font-size: 2.3rem;
	text-transform: uppercase;
	font-weight: 800;
}

input.form-control-lg {
	font-size: 2.6rem;
	text-transform: uppercase;
	font-weight: 800;
	height: 4.3rem;
}

.about-img {
	width: 85%;
	height: auto;
	padding: 12px;
	margin-top: 50px;
}

.school-img {
	border-radius: 12px;
}
