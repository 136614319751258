.maincontent {
	padding: 20px;
	border-radius: 4px;
	margin-bottom: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.page-container {
	padding: 50px;
}

.main-container {
	padding: 40px 100px;
}

p {
	margin-bottom: 12px;
}

h3,
h4,
h5 {
	margin-top: 12px;
}
