.questionDiv {
	min-height: 400px;
	padding: 8px;
}

.questionBox {
	height: 240px;
}

.question {
	font-size: 2.1em;
	font-weight: bolder;
}

.text-sm {
	font-size: 0.7em;
}

.slow {
	animation-duration: 1.3s !important;
	animation-delay: 1s;
	animation-timing-function: ease-in-out;
}
