.chatWindow {
	position: fixed;
	bottom: 70px;
	left: 10px;
	width: 330px;
	height: 60%;

	border: 2px solid #add8e6;
	background-color: #fff;
	padding: 10px;
	z-index: 1000;
	border-radius: 5px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.chatHistory {
	height: 90%;
	background-color: "blue";
	overflow-y: "scroll";
	display: "flex";
	flex-direction: "column";
	justify-content: "flex-end";
	align-items: end;
	scroll-behavior: auto;
	overflow-y: scroll;
}

.chatMessageBox {
	position: absolute;

	bottom: 74px;
	width: 96%;
	left: 2px;
	right: 5px;
}

.boxItems {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 6px;
}

.w-chat {
	text-align: right;
	margin-bottom: 0px;
}

.w-chat > a {
	color: white;
	background-color: black;
	padding: 3px;
	border-radius: 5px;
	font-size: 8px;
	font-weight: bold;
	font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

@media (max-width: 768px) {
	.chatWindow {
		width: 94%;
		left: 3%;
		height: 70%;
	}
}
